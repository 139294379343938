<!-- © 2021 Tfarraj -->
<template>
  <div id="channel">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>
