<!-- © 2021 Tfarraj -->
<template>
  <div id="channel-home">
    <v-parallax
      height="230"
      class="responsive-img"
      src="/images/channel-screen.jpg"
      jumbotron
    ></v-parallax>
    <div class="nav-bgcolor">
      <div id="channel-header">
        <v-skeleton-loader
          type="list-item-avatar-two-line"
          :loading="loading"
          class="mr-1"
        >
          <v-row class="justify-space-between py-0">
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-card class="transparent" flat>
                <v-list-item three-line>
                  <v-list-item-avatar size="80">
                    <img-with-auth
                      v-if="channel.photo"
                      :id="channel.id"
                      :type="'channel'"
                    />

                    <v-avatar v-else color="indigo darken-4" size="60">
                      <span v-if="channel.title" class="white--text headline">
                        {{ channel.title.split('')[0].toUpperCase() }}</span
                      >
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="align-self-auto">
                    <v-list-item-title class="headline mb-1">{{
                      channel.title
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ channel.subscribers }} {{ $t('home.subscribers') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" v-if="!loading">
              <div class="d-flex flex-row-reverse mb-6 bg-surface-variant">
                <v-btn
                  v-if="channel && getChannel && channel.id !== getChannel._id"
                  :class="[
                    { 'red white--text': !subscribed },
                    { 'grey grey--text lighten-3 text--darken-3': subscribed },
                    'mt-6 ma-2 pa-2',
                  ]"
                  tile
                  large
                  x-small
                  depressed
                  :loading="subscribeLoading"
                  @click="subscribe"
                >
                  {{
                    !subscribed
                      ? `${this.$i18n.t('history.subscribe')}`
                      : `${this.$i18n.t('history.subscribed')}`
                  }}
                </v-btn>
                <v-btn
                  v-if="channel && getChannel && channel.id !== getChannel._id"
                  tile
                  large
                  depressed
                  :loading="subscribeLoading"
                  :class="[
                    {
                      'grey grey--text lighten-3 text--darken-3':
                        !isBlockedByUserChannel,
                    },
                    {
                      'red grey--text lighten-3 text--darken-3':
                        isBlockedByUserChannel,
                    },
                    'mt-6 ma-2 pa-2',
                  ]"
                  x-small
                  @click="block"
                  >{{
                    isBlockedByUserChannel
                      ? $t('block.unblock')
                      : $t('block.block')
                  }}</v-btn
                >
                <!-- <v-btn icon class="ma-2 pa-2 ml-5 mt-6"
                ><v-icon>mdi-bell</v-icon></v-btn
              > -->
              </div>
            </v-col>
          </v-row>
        </v-skeleton-loader>
      </div>
      <v-card flat class="transparent">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          show-arrows
          centered
          center-active
        >
          <v-tab v-for="(item, i) in items" :key="i">
            {{ $t(item) }}
          </v-tab>
        </v-tabs>

        <v-container fluid>
          <v-tabs-items v-model="tab" class="transparent">
            <v-tab-item>
              <v-card class="transparent" flat>
                <v-card-title>{{ $t('home.uploads') }}</v-card-title>
                <!-- <v-sheet class="mx-auto"> -->
                <v-slide-group class="pa-4" multiple show-arrows>
                  <v-slide-item
                    v-for="(video, i) in loading ? 5 : videos.data"
                    :key="i"
                  >
                    <v-skeleton-loader
                      type="card-avatar"
                      :loading="loading"
                      width="250px"
                      class="mr-1"
                    >
                      <video-card
                        :card="{ maxWidth: 250, type: 'noAvatar' }"
                        :video="video"
                      ></video-card>
                    </v-skeleton-loader>
                  </v-slide-item>
                </v-slide-group>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="transparent" flat>
                <v-card-title>{{ $t('home.uploads') }}</v-card-title>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    v-for="(video, i) in loading ? 10 : videos.data"
                    :key="i"
                    class="mx-xs-auto"
                  >
                    <v-skeleton-loader type="card-avatar" :loading="loading">
                      <video-card :card="{ maxWidth: 350 }" :video="video">
                      </video-card>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </div>
    <dialog-modal
      :openModal="showDialog"
      :details="details"
      @closeModal="showDialog = false"
    />
    <report-modal
      :open-dialog="reportDialog"
      v-on:closeDialog="reportDialog = false"
      :channelId="channel.id"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Config from '@/config';
import SubscriptionService from '@/services/SubscriptionService';
import ChannelService from '@/services/ChannelService';
import VideoCard from '@/components/VideoCard';
import DialogModal from '@/modals/DialogModal';
import ImgWithAuth from '@/components/ImgWithAuth';
import VideoService from '@/services/VideoService';
import ReportModal from '@/modals/ReportModal';

export default {
  data() {
    return {
      reportDialog: false,
      tab: null,
      loading: false,
      errored: false,
      subscribed: false,
      isBlockedByUserChannel: false,
      subscribeLoading: false,
      blockedLoading: false,
      showSubBtn: true,
      url: Config.apiUrl,
      items: [
        'home.home',
        'home.videos',
        'home.playlists',
        'home.community',
        'home.channels',
        'home.about',
      ],
      videos: {},
      channel: {},
      showDialog: false,
      details: {},
    };
  },
  computed: {
    ...mapGetters([
      'getIsAuthenticated',
      'getIsUserActive',
      'getUser',
      'getChannel',
      'getUrl',
      'getSubscribedChannels',
    ]),
  },
  components: {
    VideoCard,
    DialogModal,
    ImgWithAuth,
    ReportModal,
  },
  methods: {
    async getUserChannel(id) {
      this.loading = true;
      this.errored = false;

      const channel = await ChannelService.getChannelById(id)
        .catch((err) => {
          this.errored = true;
          this.$router.push('/');
        })
        .finally(() => (this.loading = false));
      if (!channel) return;
      this.channel = channel.data;
      this.isBlockedByUserChannel = this.channel.isBlockedByUserChannel;
      if (this.getChannel && this.getChannel.id === this.channel.id) {
        this.showSubBtn = false;
      } else {
        this.showSubBtn = true;
      }
      this.getVideos();

      this.checkSubscription(this.channel.id);
    },
    async getVideos() {
      // this.getChannel()
      this.loading = true;

      const videos = await VideoService.getAll({
        channel: this.$route.params.id,
      })
        .catch((err) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));

      if (typeof videos === 'undefined') return;

      this.videos = videos.data;
    },
    async checkSubscription(id) {
      if (!this.getUser) return;
      this.loading = true;
      const sub = this.getSubscribedChannels.find(
        (channel) => channel.channel == id || channel.id == id,
      );
      if (!sub) return;

      if (!(sub.channel || sub.id)) this.subscribed = false;
      else this.subscribed = true;
    },
    async subscribe() {
      if (!this.getIsAuthenticated) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('home.wantToSubscribeToThisChannel'),
          text: this.$i18n.t('home.signInToSubscribeToThisChannel'),
          buttonText: 'signin',
        };
        return;
      }
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }
      this.subscribeLoading = true;
      if (this.subscribed) {
        const sub = await SubscriptionService.removeSubscription(
          this.channel._id,
        )
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.subscribeLoading = false;
          });

        if (!sub) return;
        if (sub.data._id) {
          this.subscribed = false;
          let subscribedChannels = [];
          subscribedChannels = this.getSubscribedChannels.filter(
            (subscribedChannel) => subscribedChannel._id !== this.channel._id,
          );
          localStorage.setItem(
            'subscribedChannels',
            JSON.stringify(subscribedChannels),
          );
          this.$store.commit('SET_SUBSCRIBED_CHANNEL_DATA', subscribedChannels);
        }
      } else {
        const sub = await SubscriptionService.createSubscription(
          this.getChannel.id,
          this.channel._id,
        )
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.subscribeLoading = false;
          });

        if (!sub) return;
        if (sub.data._id) {
          this.subscribed = true;
          const subscribedChannels = [];
          subscribedChannels.push(...this.getSubscribedChannels);
          subscribedChannels.push(this.channel);
          localStorage.setItem(
            'subscribedChannels',
            JSON.stringify(subscribedChannels),
          );
          this.$store.commit('SET_SUBSCRIBED_CHANNEL_DATA', subscribedChannels);
        }
      }
    },
    async block() {
      if (!this.getIsAuthenticated) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('home.wantToSubscribeToThisChannel'),
          text: this.$i18n.t('home.signInToSubscribeToThisChannel'),
          buttonText: 'signin',
        };
        return;
      }
      if (!this.getIsUserActive) {
        this.showDialog = true;
        this.details = {
          title: this.$i18n.t('dialogModal.actionNotPermitted'),
          text: this.$i18n.t('dialogModal.pleaseVerifyYourAccount'),
          textNote: this.$i18n.t('dialogModal.pleaseNote'),
          buttonText: 'close',
        };
        return;
      }
      this.blockedLoading = true;
      if (this.isBlockedByUserChannel) {
        this.isBlockedByUserChannel = false;
        ChannelService.unBlockChannel(this.getChannel.id, this.channel._id)
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.blockedLoading = false;
          });
      } else {
        this.isBlockedByUserChannel = true;
        ChannelService.blockChannel(this.getChannel.id, this.channel._id)
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.blockedLoading = false;
          });
      }
    },
  },
  mounted() {
    this.getUserChannel(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.getUserChannel(to.params.id);
    next();
  },
};
</script>

<style>
.nav-bgcolor {
  background: #f9f9f9;
}

.card {
  background: black !important;
}

.v-tab {
  margin-right: 4em;
}

#channel-home .v-list-item--link:before {
  background-color: transparent;
}
</style>
