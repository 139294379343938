<!-- © 2021 Tfarraj -->
<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="fab-transition"
    max-width="800"
  >
    <v-card tile>
      <div class="d-flex justify-space-between mb-5">
        <v-card-title class="py-3">{{
          $t('subscribersModal.subscribers')
        }}</v-card-title>

        <v-btn icon text @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text class="px-3">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 px-0">
            <v-data-table
              :headers="headers"
              :items="subscribers"
              :loading="loading"
              sort-by="calories"
            >
              <template v-slot:item.channel="{ item }">
                <v-list-item-avatar>
                  <img-with-auth
                    v-if="item.subscriber.photo"
                    class="elevation-6"
                    :id="item.subscriber.id"
                    :type="'channel'"
                  />
                  <v-avatar v-else color="indigo darken-4">
                    <span class="white--text headline">
                      {{
                        item.subscriber.title.split('')[0].toUpperCase()
                      }}</span
                    >
                  </v-avatar>
                </v-list-item-avatar>
                <span class="pl-2">{{ item.subscriber.title }}</span>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <span class="pl-2 font-weight-light grey--text">
                  {{ formatDate(item.createdAt) }}
                </span>
              </template>
              <!-- <template v-slot:item.actions="{ item }">
                <v-btn
                  text
                  x-small
                  class="mr-2 indigo--text text--darken-4"
                  @click="subscribe(item)"
                >
                  Subscribe
                </v-btn>
              </template> -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">{{
                  $t('videoCard.views')
                }}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionService from '@/services/SubscriptionService';
import ImgWithAuth from '@/components/ImgWithAuth';
import DateMixin from '@/mixins/DateMixin.ts';

export default {
  name: 'SubscribersModal',
  mixins: [DateMixin],
  props: ['openDialog'],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t('subscribersModal.channel'),
          align: 'start',
          value: 'channel',
        },
        {
          text: this.$i18n.t('subscribersModal.dateSubscribed'),
          value: 'createdAt',
        },
        {
          text: this.$i18n.t('subscribersModal.subcriberCount'),
          value: 'subscriber.subscribers',
        },
        // { text: 'Actions', value: 'actions', align: 'end', sortable: false }
      ],
      subscribers: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['getUrl', 'getChannel', 'getUser']),
    dialog() {
      return this.openDialog;
    },
  },
  methods: {
    async getSubscribers() {
      this.loading = true;
      const channel = this.getChannel.id;
      const res = await SubscriptionService.getSubscribers(0, channel)
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          this.loading = false;
        });

      if (!res) return;
      this.subscribers = res.data;
    },
    // initialize() {
    //   this.subscribers = [
    //     {
    //       channelName: 'Brad Traversy',
    //       avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',
    //       createdAt: 'Apr 20, 2020',
    //       subscribers: 5
    //     },
    //     {
    //       channelName: 'John Doe',
    //       avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',
    //       createdAt: 'Apr 20, 2020',
    //       subscribers: 10
    //     }
    //   ]
    // },
    initialize() {
      // TODO implement this later
    },
    subscribe(item) {},
    closeModal() {
      this.$emit('closeDialog');
    },
  },
  components: {
    ImgWithAuth,
  },
  mounted() {
    // this.initialize()
    this.getSubscribers();
  },
};
</script>
