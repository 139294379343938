<!-- © 2021 Tfarraj -->
<template>
  <div id="studio" class="pa-4">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>
